import debounce from 'lodash/debounce'
import { createStore } from '~/store/createStore'

export type Parameter = {
  name: string
  value: number
}

type State = {
  //策略用----------
  /** 商品類型 */
  symbolChannel?: string
  /** 商品名稱 */
  symbolNumber?: string
  /** 成本 */
  entryPrice?: number
  /** 防守 */
  stopLossPrices?: number[]
  /** 目標 */
  targetPrices?: number[]
  /** 進場時間 */
  entryTime?: number
  /** 部位 */
  marketPosition?: number
  //--------------
  /** 當根K開盤 */
  nowOpen?: number
  /** 當根K高 */
  nowHigh?: number
  /** 當根K低 */
  nowLow?: number
  /** 當根K收盤 */
  nowClose?: number
  /** 當根K成交量 */
  nowVolume?: number
  //---------------
  /** 當日開盤 */
  openD?: number
  /** 當日最高 */
  highD?: number
  /** 當日最低 */
  lowD?: number
  /** 當日收盤 */
  closeD?: number
  /** 昨日收盤 */
  prevRefClose?: number
  //---------------
  /** 前一根開盤數值 */
  lastOpen?: number
  /** 前一根高數值 */
  lastHigh?: number
  /** 前一根低數值 */
  lastLow?: number
  /** 前一根收盤數值 */
  lastClose?: number
  /** 停利點1 */
  target1?: number
  /** 停利點2 */
  target2?: number

  //策略參數----------
  /** 參數1 */
  parameter_1?: Parameter[]
  /** 停損點 */
  stopLossPoint?: number
  /** 停利點 */
  targetPoint?: number
  /** 停利類型 百分比or點數 */
  targetType?: 'point' | 'percent'
  /** 百分比基準點 SetPercentTrailing */
  percentProfit?: number
  /** 百分比拉回%數 SetPercentTrailing */
  percentage?: number
  /**
   * `1` 代表無小數點
   *
   * `100` 代表小數點二位
   */
  /** 傳參用 */
  value1?: number
  value2?: number
  value3?: number
  value4?: number
  value5?: number
  value6?: number
  value7?: number
  value8?: number
  /** 傳參用 */
  valueArray1?: number[]
  pricescale?: number
  /** 停利階段 */
  targetFlag?: number
  /**
   * 改用 `indicatorStoreSetStateDebounced` 取代這個 method 可使 indicator 不會這麼頻繁的 setState 造成 UI render 過快
   *
   * @deprecated
   */
  update(payload: State): void
}

/** 用於將 indicator 內部算得出來的「進場價」等等資訊傳遞出來 */
export const useIndicatorStore = createStore<State>(set => ({
  update(payload) {
    indicatorStoreSetState(payload)
  },
}))

export const indicatorStoreSetState = (payload: State) => {
  useIndicatorStore.setState({ ...payload })
}

export const indicatorStoreSetStateDebounced = debounce((payload: State) => {
  useIndicatorStore.setState({ ...payload })
}, 333)
