import React, { Fragment, memo, useEffect, useMemo, useState } from 'react'
import { proxy, useSnapshot } from 'valtio'
import styled from '@emotion/styled'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { createChartStore } from '~/modules/SDK/Chart2/createChartStore'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { css } from '@emotion/react'

export type ChartPosition = 'long' | 'short' | 'default'

export const useChartPositionStore = proxy({
  positionType: 'default' as ChartPosition,
})

type BasicProps = {
  onStatesColor?: string
  backgroundColor?: string
}

const ButtonStyled = styled.div<BasicProps>`
  ${flex.v.allCenter};
  width: 100%;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
  &.long-style {
    background-color: ${props => props.backgroundColor ?? '#aa0000'};
    &:hover {
      background-color: ${props => (props.backgroundColor ?? '#aa0000') + 'dd'};
    }
  }
  &.short-style {
    background-color: ${props => props.backgroundColor ?? '#00aa00'};
    &:hover {
      background-color: ${props => (props.backgroundColor ?? '#00aa00') + 'dd'};
    }
  }
  &.default-style {
    background-color: ${props => props.backgroundColor ?? '#555555'};
    &:hover {
      background-color: ${props => (props.backgroundColor ?? '#555555') + 'dd'};
    }
  }
  user-select: none;
`

const buttonCss = css`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
`

export const ChartPositionButton = memo<
  ReactProps<{
    useChart: ReturnType<typeof createChartStore>
    useIndicators: ChartTypes.Indicator[]
    usePosition: ChartPosition
  }>
>(function ChartPositionButton_(props) {
  const positionState = useSnapshot(useChartPositionStore)
  const selectedClasses =
    props.usePosition === positionState.positionType
      ? chartPositionButtonClasses.selected
      : chartPositionButtonClasses.default

  return (
    <props.useChart.IndicatorsSwitch
      indicators={props.useIndicators}
      css={buttonCss}
      className={props.usePosition + '-style' + ' ' + selectedClasses}
    >
      <ButtonStyled onClick={() => (useChartPositionStore.positionType = props.usePosition)}>
        {props.children}
      </ButtonStyled>
    </props.useChart.IndicatorsSwitch>
  )
})

export const chartPositionButtonClasses = {
  default: `ChartPositionButton-default`,
  selected: `ChartPositionButton-selected`,
}
