import React, { Fragment, memo } from 'react'
import { css } from '@emotion/react'
import { NextPage } from 'next'
import { Price } from '~/components/Price'
import { createDesktopCss } from '~/css/createDesktopCss'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { fontWeight600 } from '~/css/font'
import { flex, jc } from '~/modules/AppLayout/FlexGridCss'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { InvestmentConsultantSubscriptionCheck } from '~/modules/auth/components/UserSubscriptionCheckAsFullscreen'
import { createChartStore } from '~/modules/SDK/Chart2/createChartStore'
import { LayoutModule } from '~/modules/SDK/Layout/layout/LayoutModule'
import { meCheckHandlerAgentWeb } from '~/modules/SDK/me/meCheckHandlerAgentWeb'
import { useIndicatorStore } from '~/store/useIndicatorStore'

/* indicators */
import { bb1_new } from '~/trades/indicators/hyt888/bb1_new'
import { hma1_new } from '~/trades/indicators/hyt888/hma1_new'
import { sg1_new } from '~/trades/indicators/hyt888/sg1_new'
import { sma1_new } from '~/trades/indicators/hyt888/sma1_new'

import { TradeBoard } from '../winner98_full_version/private_/winner98_fullVersion_SidebarPane'

const capitalFuturesTradeUrl =
  'https://capitalh5.capital.com.tw/Login.aspx?Action=A&DisplayCond=F&DefaultCond=F'

export const useHyt888Chart = createChartStore({
  preparedCustomIndicators: [bb1_new, hma1_new, sg1_new, sma1_new],
  defaultsOptions: {
    customIndicators: [bb1_new, hma1_new, sg1_new, sma1_new],
    enableVolumeIndicator: false,
    disabledHeaderWidget: false,
    disabledLeftToolbar: false,
    disabledTimeframesToolbar: true,
    symbol: 'TX-1',
    interval: 60,
    client_id: 'hyt888',
    overrides: {
      'mainSeriesProperties.candleStyle.upColor': '#FD0000',
      'mainSeriesProperties.candleStyle.downColor': '#00FE01',
      'mainSeriesProperties.candleStyle.borderUpColor': '#FD0000',
      'mainSeriesProperties.candleStyle.borderDownColor': '#00FE01',
      'mainSeriesProperties.candleStyle.wickUpColor': 'rgba( 115, 115, 117, 1)',
      'mainSeriesProperties.candleStyle.wickDownColor': 'rgba( 115, 115, 117, 1)',
      'mainSeriesProperties.candleStyle.drawBorder': false,
      'paneProperties.topMargin': 15,
      'paneProperties.bottomMargin': 25,
      'timeScale.rightOffset': 20,
      'paneProperties.vertGridProperties.color': '#000000',
      'paneProperties.horzGridProperties.color': '#000000',
      'paneProperties.background': '#000000',
    },
  },
})

const layout = new LayoutModule()
layout.store.TopAndLeftMain.customCSS = css`
  grid-template-rows: 64px auto;
  grid-template-columns: 0 1fr;
  gap: 0;
  ${createMobileCss2(css`
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr 0;
  `)}

  ${createIPadCss(css`
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr 0;
  `)}
`
layout.store.Top.customCSS = css`
  ${flex.wrap.default};
  ${jc.spaceBetween};
  padding: 4px 16px;
  gap: 8px;
  ${createDesktopCss(css`
    justify-content: flex-end;
    align-items: center;
  `)}
`

export const Hyt888NextPage: NextPage = memo(function Hyt888NextPage(props) {
  const legitUser = meCheckHandlerAgentWeb.useCheck()
  return (
    <>
      <InvestmentConsultantSubscriptionCheck show={!legitUser} />
      <layout.TopAndLeftMain>
        <layout.Top>
          {Prices}
          {H5TradeButton}
          <useHyt888Chart.ServerSwitch />
          <UserAvatarAsDialogButton />
        </layout.Top>
        <layout.Main>{legitUser && <useHyt888Chart.Chart />}</layout.Main>
      </layout.TopAndLeftMain>
    </>
  )
})

const HoldingCostPrice = memo(function HoldingCostPrice(props) {
  const pricescale = useIndicatorStore(state => state.pricescale)
  const entryPrice = useIndicatorStore(state => state.entryPrice)
  const isShortHolding = typeof entryPrice === 'number' && entryPrice < 0 ? true : false

  return (
    <div css={flex.h.crossCenter}>
      <p css={priceHeaderCss}>{isShortHolding ? '空單' : '多單'}成本</p>

      <PriceChip
        value={entryPrice ?? 0}
        pricescale={pricescale}
      />
    </div>
  )
})

export const DefensePrice = memo(function DefensePrice(props) {
  const pricescale = useIndicatorStore(state => state.pricescale)
  const stopLossPrices = useIndicatorStore(state => state.stopLossPrices)

  return (
    <div css={flex.h.crossCenter}>
      <p css={priceHeaderCss}>防守</p>
      {stopLossPrices?.map((price, index) => (
        <Fragment key={index}>
          <PriceChip
            value={price}
            pricescale={pricescale}
          />
        </Fragment>
      ))}
    </div>
  )
})

export const TargetPrice = memo(function TargetPrice(props) {
  const pricescale = useIndicatorStore(state => state.pricescale)
  const targetPrices = useIndicatorStore(state => state.targetPrices)

  return (
    <div css={flex.h.crossCenter}>
      <p css={priceHeaderCss}>目標</p>
      {targetPrices?.map((price, index) => (
        <Fragment key={index}>
          <PriceChip
            value={price}
            pricescale={pricescale}
          />
        </Fragment>
      ))}
    </div>
  )
})

const H5TradeButton = (
  <TradeBoard.orderButton
    active={true}
    fill='#aaaaaa'
    onClick={() => {
      window.open(
        capitalFuturesTradeUrl,
        'WindowOpen',
        'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=500, height=640, top=0, left=0',
      )
    }}
  >
    群益下單
  </TradeBoard.orderButton>
)

const Prices = (
  <div
    css={css`
      ${flex.wrap.default};
      gap: 8px;
    `}
  >
    <HoldingCostPrice />
    <DefensePrice />
    <TargetPrice />
  </div>
)

const PriceChip = memo<
  ReactProps<{
    value: number
    pricescale?: number
  }>
>(function PriceChip(props) {
  return (
    <Price
      css={css`
        border-radius: 16px;
        padding: 2px 8px;
        margin-right: 4px;
      `}
      colored
      value={Math.abs(props.value)}
      pricescale={props.pricescale ?? 1}
    />
  )
})

const priceHeaderCss = css`
  margin: 0 8px 0 0;
  ${fontWeight600};
  font-size: 18px;
`
export default Hyt888NextPage
