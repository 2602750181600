/* eslint-disable react/display-name */
import { css } from '@emotion/react'
import { memo, Children, cloneElement, Fragment, isValidElement } from 'react'
import { proxy, ref, useSnapshot } from 'valtio'

/**
 * @example
 *   export const good178Stock_store = proxy({
 *     // 其它的 store.state
 *     usedStrategyTrend: 'default' as 'long' | 'short' | 'default',
 *
 *     // 💡我們可以在「投顧 store」裡，管理需要的 tabs
 *     tabs: new FuiTabs2(['選股', '當日訊號', '自選股']),
 *   })
 *
 *   export const Good178Stock_Sidebar1 = memo<ReactProps>(function Good178Stock_Sidebar1(props) {
 *     return (
 *       <div>
 *         <good178Stock_store.tabs.Button to='選股'>
 *           {`💡自動處理 onClick=showTab('選股')`}
 *           {`💡自動注入 active={state.active === '選股'}`}
 *           <FuiButton.Display>選股</FuiButton.Display>
 *         </good178Stock_store.tabs.Button>
 *
 *         {`💡當 state.active === '選股' 時，才會 render 內容`}
 *         <good178Stock_store.tabs.Content for='選股'>
 *           <div>選股</div>
 *         </good178Stock_store.tabs.Content>
 *       </div>
 *     )
 *   })
 */
export class FuiTabs2<Name extends string> {
  store

  constructor(tabs: Name[]) {
    this.store = proxy({
      /** 預期要顯示的 TabContent */
      active: tabs[0],

      /** 儲存著可被選中的 TabContents */
      tabs: tabs,
    })
    this.WithButton.displayName = `${FuiTabs2.name}.WithButton`
    this.WithContent.displayName = `${FuiTabs2.name}.WithContent`
  }

  showTab(name: Name) {
    this.store.active = name
  }

  useActiveTab = (name: Name) => {
    return useSnapshot(this.store).active === name
  }

  WithButton = ref(
    memo<
      ReactProps<{
        to: Name
      }>
    >(props => {
      const state = useSnapshot(this.store)

      const child = Children.map(props.children, $child =>
        !isValidElement($child)
          ? $child
          : cloneElement($child as any, {
              active: state.active === props.to,
            }),
      )

      return (
        <div
          css={css`
            display: contents;
          `}
          onClick={event => {
            this.showTab(props.to)
          }}
        >
          {child}
        </div>
      )
    }),
  )

  WithContent = ref(
    memo<
      ReactProps<{
        for: Name
      }>
    >(props => {
      const state = useSnapshot(this.store)

      if (state.active !== props.for) {
        return null
      }

      return (
        <div
          css={css`
            display: contents;
          `}
        >
          {props.children}
        </div>
      )
    }),
  )
}
