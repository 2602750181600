import { Dayjs } from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import dayAPI from '~/utils/dayAPI'
import { useLatestTradeDateStore } from '~/modules/screener/containers/useLatestTradeDateStore'

export const useDatePick = () => {
  const base = dayAPI()
  const backendDate = useLatestTradeDateStore(s => s.date)
  const frontendDate = getReasonableDate(base)
  const pickDate = !isEmpty(backendDate) ? backendDate : frontendDate
  return pickDate
}

export const useIntradayPick = () => {
  const backendDate = useLatestTradeDateStore(s => s.intraday)
  return backendDate
}

/**
 * 以防後端沒有回傳最新交易日紀錄，前端時間邏輯
 *
 * 大約六點之後會有當天的統計
 */
export const getReasonableDate = (base: Dayjs) => {
  // 過六點「可能」有當天資料 && 平日
  if (base.isAfter(base.hour(18).minute(0))) {
    if (base.weekday() >= 1 && base.weekday() <= 5) return base.format('YYYY-MM-DD')
  }
  // 沒過六點，直接看星期幾取得上一次交易日（特殊節日休市不管，預設為周間1-5）
  return base
    .add(base.weekday() >= 2 ? -1 : base.weekday() === 1 ? -3 : -2, 'day')
    .format('YYYY-MM-DD')
}
