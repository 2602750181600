import { useState, useCallback } from 'react'
import { useMount } from 'react-use'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { getFirebaseStore } from '~/modules/SDK/firebase/getFirebase'

export type SignalSymbolArray = {
  symbols: string[]
}

/** 傳入 collection, document 名字，並且賦予型別，回傳 data  */
export const useFirebaseValue = <T,>(
  collection: string,
  document: 'long' | 'short' | 'signal',
): T | undefined => {
  const firestore = getFirebaseStore()
  const collectionRef = firestore().collection(collection)
  const { meUserState } = useMeStore()
  const userCode = meUserState?.uid || undefined
  const [data, setData] = useState<T>()

  const getValue = useCallback(() => {
    if (userCode) {
      collectionRef.doc(document).onSnapshot(doc => setData(doc.data() as T))
    }
  }, [collectionRef, document, userCode])

  useMount(() => {
    getValue()
  })

  return data
}
