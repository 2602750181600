import { css } from '@emotion/react'
import Big from 'big.js'
import { Fragment, memo, useMemo } from 'react'
import { LiteralUnion } from 'type-fest'

export enum PriceTestid {
  primaryValue = 'mainValue',
  secondaryValue = 'secondaryValue',
  decimalText = 'decimalText',
}

/**
 * 「價格/價位/盈虧」顯示用組件
 *
 * ## WHY
 *
 * - 不再煩腦 javascript 小數點精度問題：內部使用 big.js 處理
 */
export const Price = memo<
  ReactProps<{
    /** 任一純數字，內部會進行精度處理 */
    value: number
    /**
     * 改用「props.pricescale」替代
     *
     * 是否四捨五入？預設 false；使用四捨五入相當於「無小數點」
     *
     * @deprecated
     */
    round?: boolean
    /** 自動上色，正數為綠，負數為紅 */
    colored?: boolean
    /**
     * 小數點精度，自帶四捨五入
     *
     * Defaults = 1
     *
     * 1 = 無小數點
     *
     * 10 = 小數點 1 位
     *
     * 100 = 小數點 2 位
     *
     * 1000 = 小數點 3 位
     */
    pricescale?: LiteralUnion<1 | 10 | 100 | 1000, number>
  }>
>(function Price(props) {
  const pricescale = props.round ? 1 : props.pricescale ?? 100
  const backgroundColor = css`
    background-color: ${props.colored && props.value >= 0 ? '#088100' : '#910000'};
  `

  const value = useMemo(() => {
    /** Given value as `123.64` get the value as `'123'` */
    const floorValue = new Big(props.value ?? 0)
      .round(0, pricescale === 1 || props.round ? 1 : 0)
      .toString()

    /** Given value as `123.64` get the value as `'64'` */
    const decimalValue = new Big(props.value ?? 0)
      .minus(floorValue)
      .toFixed(props.round ? 0 : pricescale.toString().length - 1)
      .split('.')[1]

    return {
      floorValue,
      decimalValue,
    }
  }, [props.value, props.round, pricescale])

  return (
    <span
      className={props.className}
      css={css`
        ${props.colored && backgroundColor}
      `}
    >
      <Fragment>
        <span data-testid={PriceTestid.primaryValue}>{value.floorValue}</span>

        {pricescale > 1 && (
          <span
            css={css`
              font-size: 0.75rem;
              filter: opacity(0.5);
            `}
          >
            <span data-testid={PriceTestid.decimalText}>{'.'}</span>
            <span data-testid={PriceTestid.secondaryValue}>{value.decimalValue}</span>
          </span>
        )}
      </Fragment>
    </span>
  )
})
